export const REGISTRATION_TAG_PATHS = {
  how: "InfoDonor",
  requirements: "InfoDonor",
  blooddonation: "InfoDonor",
  "donorstvo-kostnogo-mozga": "InfoDonor",
  about: "InfoProject",
  smi: "InfoProject",
  awards: "Awards",
  "non-profit": "InfoProject",
  event: "Event",
  organization: "Organization",
  bonus: "Bonus",
  bloodstation: "Blood",
  recipient: "Recipient",
  helpme: "Helpme",
  top: "Top",
  advice: "Advice",
  pre_sign_up: "Register",
  confidential: "Register",
  rules: "Register",
  "co-donations": "CoDonation",
  "business-hr": "BusinessHr",
  "business-marketing": "BusinessMarketing",
  "corporate-donation": "CorporateDonation",
  donate: "Donate",
  gratitude: "Gratitude",
  offer: "Offer",
  partnership: "Partnership",
  partners: "Partners",
  souvenir: "Souvenir"
};
